<template>
    <div class="operation-cert-level full-page">
      <CommonTitle :titles="titles">
      </CommonTitle>
      <div class="cert-info">
        <a-form
          :form="form"
          :layout="formLayout"
          :label-col="labelCol.sm"
          :wrapper-col="wrapperCol.sm">
          <a-form-item
            label="等级组名称"
          >
            <a-input
              v-decorator="[
                'name',
                { initialValue: name, rules: [{ required: true, message: '请输入等级组名称' }]}
              ]"
              placeholder="等级组名称">
            </a-input>
          </a-form-item>
          <a-form-item
            label="级数"
          >
            <a-select v-model="level" @change="changeLevel">
              <template v-for="item in levelOptions">
                <a-select-option
                  :key="item.value"
                  :value="item.value">
                  {{item.name}}
                </a-select-option>
              </template>
            </a-select>
          </a-form-item>
          <a-form-item
            label="认证等级名称"
          >
            <p class="level-tip">等级1为最低等级</p>
          </a-form-item>
          <div class="level-content">
            <a-form-item
              v-for="(item, index) in levelList"
              :key="index"
              :label-col="labelCol.level"
              :wrapper-col="wrapperCol.xs"
              :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
              :label="`级别${index + 1}`"
            >
              <a-input
                v-model="levelList[index].levelName"
                class="level-info"
                placeholder="填写等级名称"
              ></a-input>
              <upload-image
                class="ml-20"
                :pic="levelList[index].levelIcon"
                @upload-success="uploadSuccess"
                @preview="previewImg(levelList[index].levelIcon)"
                @delete="deleteImg(levelList[index])"
                :level-index="index"
                :is-read-only="readOnly">
              </upload-image>
            </a-form-item>
            <p class="level-warn">默认存在未认证等级</p>
          </div>
          <a-form-item
            label="适用岗位"
            :wrapper-col="wrapperCol.ls"
          >
            <div v-for="(item, index) in jobList" :key="item.id" class="job-content">
              <div class="job-list">
                <a-checkbox
                  :indeterminate="indeterminate[index]"
                  @change="onCheckAllChange(item, index, $event)"
                  :checked="checkAll[index]">
                  {{item.title}}
                </a-checkbox>
              </div>
              <a-checkbox-group
                v-if="item.children"
                v-model="checkList[index]"
                @change="onChange(index, $event)"
                class="mt-20">
                <template v-for="item1 of item.children">
                  <a-checkbox
                    :key="item1.id"
                    :value="item1.id"
                    :disabled="item1.is_use === 1 && !isEdit"
                    class="job-item">
                    {{ item1.name }}
                  </a-checkbox>
                </template>
              </a-checkbox-group>
            </div>
          </a-form-item>
          <a-form-item :wrapper-col="wrapperCol.xs" class="operation-btn">
            <a-button type="primary" @click="handleSubmit">
              保存
            </a-button>
            <a-button @click="handleCancel">
              取消
            </a-button>
          </a-form-item>
        </a-form>
      </div>

      <ImageViewer ref="imageViewerRef" :url="previewImageUrl"/>
    </div>
</template>

<script>
  import CommonTitle from '@components/CommonTitle';
  import ImageViewer from '@components/ImageViewer';
  import UploadImage from "@components/UploadImage";
  import {getJobList, handleEditCert, handleAddCert, getCertDetail, doCheckInfo} from '@/api/userConfig/Index';
  export default {
    name: "OperationCertLevel",
    components: {
      CommonTitle,
      ImageViewer,
      UploadImage,
    },
    computed: {
      titles() {
        const pageType = this.$route.query.type;
        if (pageType === '0') {
          return [{title: '岗位管理'}, {title: '认证等级管理'}, {title: '认证等级新增'}];
        } else if (pageType === '1') {
          return [{title: '岗位管理'}, {title: '认证等级管理'}, {title: '认证等级编辑'}];
        } else {
          return [];
        }
      }
    },
    watch: {
      level: {
        handler(newVal, oldVal) {
          if (oldVal !== newVal && this.$route.query.type !== '2') {
            const _list = [];
            for (let i = 0; i < +newVal; i++) {
              let obj = {
                levelName: '',
                levelIcon: ''
              }
              if (this.levelList[i] && this.levelList[i].levelName !== '') {
                obj.levelName = this.levelList[i].levelName;
              }
              if (this.levelList[i] && this.levelList[i].levelIcon !== '') {
                obj.levelIcon = this.levelList[i].levelIcon;
              }
              _list.push(obj)
            }
            this.levelList = _list;
            console.log(this.levelList);
          }
        },
        immediate: true
      }
    },
    data() {
      return {
        previewImageUrl: '',
        formLayout: 'horizontal',
        form: this.$form.createForm(this, { name: 'dynamic_rule' }),
        name: '', // 等级组名称
        readOnly: false,
        indeterminate: [
          false,
          false,
          false
        ],
        checkAll: [
          false,
          false,
          false
        ],
        checkList: [
          [],
          [],
          []
        ],
        levelList: [],
        labelCol: {
          sm: { span: 2 },
          level: { span: 4 }
        },
        wrapperCol: {
          sm: { span: 3 },
          xs: { span: 12 },
          ls: { span: 20 }
        },
        jobParams: {
          id: this.$route.query.id || 0
        },
        isEdit: this.$route.query.type === '1',
        jobList: [], // 岗位列表
        level: '1',
        contentImg: [],
        updateParams: {},
        levelOptions: [
          {
            name: '1',
            value: '1'
          },
          {
            name: '2',
            value: '2'
          },
          {
            name: '3',
            value: '3'
          },
          {
            name: '4',
            value: '4'
          },
          {
            name: '5',
            value: '5'
          }
        ]
      }
    },
    created() {
      this.handleGetJobList();
    },
    methods: {
      previewImg(url) {
        this.previewImageUrl = url;
        this.$refs.imageViewerRef?.show && this.$refs.imageViewerRef.show();
      },
      deleteImg(item) {
        item.levelIcon = '';
      },
      /* 获取的等级认证详情 */
      async getLevelCertDetail() {
        // let compareList = [];
        const data = await getCertDetail(this.jobParams.id);

        if (!data || data.error_code) {
          return;
        }
        this.name = data.data.title || '';
        this.level = data.data.level.toString() || '';
        this.levelList = data.data.content_arr || [];
        const postIds = data.data.post_ids.split(',');
        this.jobList.forEach((item, index) => {
          postIds.forEach((item1) => {
            item.children.forEach((item2) => {
              if (item2.id === +item1) {
                this.checkList[index].push(+item1);
              }
            })
          })
        })
        console.log(this.checkList);
        this.checkList.forEach((item, index) => {
          this.indeterminate[index] = (item.length < this.jobList[index].children.length) && !!item.length;
          this.checkAll[index] = item.length === this.jobList[index].children.length;
          this.checkAll = [...this.checkAll];
          this.indeterminate = [...this.indeterminate];
        })
        console.log(this.indeterminate);
      },
      /* 切换等级 */
      changeLevel(val) {
        this.level = val || '';
      },
      /* 获取岗位列表 */
      async handleGetJobList() {
        const data = await getJobList(this.jobParams);

        if (!data || data.error_code) {
          return;
        }
        console.log(data);
        this.jobList = data.data;
        if (this.$route.query.type === '1') {
          await this.getLevelCertDetail();
        }
      },
      isInclude (arr1, arr2) {
        return arr1.every((item) => {
          return arr2.some((sub) => {
            return sub === item;
          });
        });
      },
      uploadSuccess(val) {
        this.levelList[val.ind].levelIcon = val?.url || '';
      },
      onChange(index, checkedList) {
        const nowArr = this.jobList[index].children.map(item => item.id);
        this.indeterminate[index] = !!checkedList.length && checkedList.length < nowArr.length
        this.checkAll[index] = checkedList.length === nowArr.length;
        this.checkAll = [...this.checkAll];
        this.indeterminate = [...this.indeterminate];
      },
      onCheckAllChange({ id }, index,  e) {
        const filterObj = this.jobList.find((item) => item.id === id);
        console.log(filterObj);
        if (e.target.checked) {
          this.checkList[index] = filterObj.children.map(item => item.id);
        } else {
          this.checkList[index] = [];
        }
        this.checkList = [...this.checkList];
        this.checkAll[index] = e.target.checked;
        this.checkAll = [...this.checkAll];
        this.indeterminate[index] = false;
        this.indeterminate = [...this.indeterminate];
      },
      /* 取消相关操作 */
      handleCancel() {
        this.$router.push('/index/userConfig/certList');
      },
      /* 提交 */
      handleSubmit() {
        this.form.validateFields((err, values) => {
          if (!err) {
            const params = {
            };
            params.title = values.name;
            params.level = this.level;
            let arr1 = [];
            for (let i = 0; i < this.levelList.length; i++) {
              let obj = {
                levelName: '',
                levelIcon: ''
              }
              if (this.levelList[i].levelIcon ==='' || this.levelList[i].levelName === '') {
                this.$message.error('请完善级别信息');
                return false;
              }
              obj.levelName = this.levelList[i].levelName;
              obj.levelIcon = this.levelList[i].levelIcon;
              arr1.push(obj);
            }
            // this.levelList.forEach((item) => {
            //   let obj = {
            //     levelName: '',
            //     levelIcon: ''
            //   }
            //   if (item.levelIcon ==='' || item.levelName === '') {
            //     this.$message.error('请完善级别信息');
            //     throw new Error("breakForEach");
            //   }
            //   obj.levelName = item.levelName;
            //   obj.levelIcon = item.levelIcon;
            //   arr1.push(obj);
            // })
            params.content = JSON.stringify(arr1);
            let postIds = [];
            if (this.checkList.length > 0) {
              this.checkList.forEach((item) => {
                postIds = postIds.concat(item)
              })
              params.post_ids = postIds.join(',');
            } else {
              params.post_ids = '';
            }
            console.log(params);
            if (this.$route.query.type === '0') {
              console.log('去新增');
              this.goAdd(params);
            } else if (this.$route.query.type === '1') {
              console.log('去修改');
              this.updateParams = params;
              this.goCheck();
            }
          }
        });
      },
      /* 校验 */
      async goCheck() {
        const data = await doCheckInfo(this.updateParams, this.jobParams.id);
        if (!data || data.error_code) {
          this.$confirm({
            content: data.message,
            onOk: () => this.goEdit(),
          });
          return;
        }
        await this.goEdit();
      },
      /* 新增 */
      async goAdd(params) {
        const data = await handleAddCert(params, this.jobParams.id);

        if (!data || data.error_code) {
          this.$message.error(data.message);
          return;
        }
        console.log(data);
        this.$message.success('保存成功!');
        await this.$router.push('/index/userConfig/certList');
      },
      /* 修改 */
      async goEdit() {
        const data = await handleEditCert(this.updateParams, this.jobParams.id);

        if (!data || data.error_code) {
          this.$message.error(data.message);
          return;
        }

        console.log(data);
        this.updateParams = {};
        this.$message.success('修改成功!');
        await this.$router.push('/index/userConfig/certList');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .operation-cert-level {
    overflow-y: auto;
    .cert-info {
      padding-left: 30px;
      padding-top: 10px;
    }
    .level-tip {
      color: rgba(0, 0, 0, 0.45);
      font-size: 14px;
      margin: 0 0 0 10px;
    }
    .level-warn {
      color: rgba(0, 0, 0, 0.45);
      font-size: 14px;
      margin: 0;
      margin-left: 8%;
    }
    .level-content {
      position: relative;
    }
    .level-list {
      .level-item {
        display: flex;
        align-items: center;
        width: 265px;
        margin-right: 30px;
        .level-info {
        }
      }
    }
    .job-list {
      border-bottom: 1px solid #E9E9E9;
    }
    .job-item {
      margin-left: 0!important;
      width: 150px;
    }
    .job-content {
      width: 33.3%;
    }
    ::v-deep .ant-form-item-children {
      display: flex;
    }
    .operation-btn {
      ::v-deep .ant-form-item-children {
        justify-content: center;
      }
      ::v-deep .ant-btn {
        width: 100px;
      }
    }
  }
</style>
