import request from '@/utils/Request';

// 用户配置-后台-获取岗位认证管理列表
export function getJobCertManagerList(params) {
  return request({
    url: '/postauth/admin',
    method: 'get',
    params,
  });
}

// 用户配置-后台-获取岗位列表
export function getJobList(params) {
  return request({
    url: '/postauth/admin/post',
    method: 'get',
    params,
  });
}

// 用户配置-后台-新增认证等级
export function handleAddCert(data) {
  return request({
    url: '/postauth/admin/create',
    method: 'post',
    data,
  });
}

// 用户配置-后台-修改认证等级
export function handleEditCert(data, id) {
  const levelId = id;
  const url = `/postauth/admin/${levelId}`
  return request({
    url,
    method: 'post',
    data,
  });
}

// 认证等级信息严重
export function doCheckInfo(data, id) {
  const levelId = id;
  const url = `/postauth/admin/checkData/${levelId}`
  return request({
    url,
    method: 'post',
    data,
  });
}

// 删除认证等级
export function doDeleteLevelCert(id) {
  const url = `/postauth/admin/${id}`
  return request({
    url,
    method: 'delete'
  });
}

export function getCertDetail(id) {
  const url = `/postauth/admin/${id}`
  return request({
    url
  });
}
