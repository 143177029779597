<template>
    <div class="cert-level-manager full-page">
      <CommonTitle :titles="titles">
        <div slot="buttons" class="btn-group">
          <a-button @click="addCertLevel">添加认证等级</a-button>
        </div>
      </CommonTitle>
      <div class="row">
        <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered
                 row-key="id" class="table small-cell-table">
          <template slot="action" slot-scope="text, record">
            <div class="table-cell-wrapper">
              <a-button type="link" size="small" @click="editItem(record)">编辑</a-button>
              <a-button type="link" size="small" @click="delItem(record)">删除</a-button>
            </div>
          </template>
          <template slot="footer">
            <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                        class="pagination"
                        @change="onPageChange"
                        @showSizeChange="onSizeChange"></Pagination>
          </template>
        </a-table>
      </div>
    </div>
</template>

<script>
  import CommonTitle from '@components/CommonTitle';
  import {getJobCertManagerList, doDeleteLevelCert} from '@/api/userConfig/Index';
  import Common from '@/utils/Common';
  import Pagination, {getPagination} from '@components/Pagination';
  const columns = [
    {
      width: '40px',
      align: 'center',
      dataIndex: 'id',
      key: 'id',
      title: 'ID',
    },
    {
      width: '180px',
      align: 'center',
      title: '名称',
      dataIndex: 'title',
      key: 'title',
    },
    {
      width: '80px',
      align: 'center',
      title: '级数',
      key: 'level',
      dataIndex: 'level',
    },
    {
      width: '250px',
      align: 'center',
      title: '各级名称(从低到高排列)',
      key: 'level_name',
      dataIndex: 'level_name',
    },
    {
      width: '180px',
      align: 'center',
      title: '适用岗位',
      dataIndex: 'post_str',
      key: 'post_str',
    },
    {
      width: '130px',
      align: 'center',
      title: '操作',
      key: 'action',
      scopedSlots: {customRender: 'action'},
    },
  ];
  export default {
    name: "List",
    components: {
      CommonTitle,
      Pagination
    },
    data() {
      return {
        titles: [{title: '岗位管理'}, {title: '认证等级管理'}],
        columns,
        data: [],
        loading: false,
        pagination: getPagination(),
      }
    },
    created() {
      this.getData();
    },
    methods: {
      /* 添加认证等级 */
      addCertLevel() {
        console.log('添加认证等级');
        this.$router.push({
          path: '/index/userConfig/certOperation',
          query: {
            type: '0'
          }
        })
      },
      /* 编辑 */
      editItem({ id }) {
        console.log('编辑去啦');
        this.$router.push({
          path: '/index/userConfig/certOperation',
          query: {
            type: '1',
            id,
          }
        })
      },
      /* 删除 */
      delItem({ id }) {
        this.$confirm({
          content: () => <div>确认删除该认证等级？</div>,
        onOk: () => {
          this.confirmDelete(id);
        },
      });
      },
      /* 删除认证等级 */
      async confirmDelete(id) {
        const data = await doDeleteLevelCert(id);
        if (!data || data.error_code) {
          this.$message.error(data.message);
          return;
        }
        await this.getData();
      },
      onPageChange(page, pageSize) {
        this.getData(page, pageSize);
      },
      onSizeChange(current, size) {
        this.getData(1, size);
      },
      async getData(page, pageSize) {
        page = page || 1;
        pageSize = pageSize || this.pagination.defaultPageSize;

        const params = {
          page: page,
          per_page: pageSize,
        };
        if (this.loading) {
          return;
        }
        this.loading = true;
        const data = await getJobCertManagerList(params).finally(() => this.loading = false);
        if (!data || data.error_code) {
          return;
        }
        data.data.forEach((item) => {
          item.level_name = item.content_arr.map((item1) => item1.levelName).join(',');
        })
        this.data = data?.data || [];

        const pagination = data?.meta?.pagination || {};
        this.pagination = Common.updatePagination(this.pagination, pagination);
      },
    }
  }
</script>

<style lang="scss" scoped>
  .cert-level-manager {
    overflow-y: auto;
  }
</style>
